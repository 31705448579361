@font-face {
  font-family: 'Haas Grot Disp Web';
  src: url('/fonts/NeueHaasGrotDisp-65Medium-Web.woff2') format('woff2'),
       url('/fonts/NeueHaasGrotDisp-65Medium-Web.woff') format('woff');
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Haas Grot Disp Web';
  src: url('/fonts/NeueHaasGrotDisp-55Roman-Web.woff2') format('woff2'),
       url('/fonts/NeueHaasGrotDisp-55Roman-Web.woff') format('woff');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}

html,
body {
  --grid-gutter: max( 1rem, 1.777rem );
  --grid-parent-gutter: calc( ( -1 * var( --grid-gutter ) ) / 2 );
  --grid-child-gutter: calc( var( --grid-gutter ) / 2 );
}